import sigV4Client from "./signV4Client";
import Utils from "../Utils";
import AWS from 'aws-sdk';
import axios from "axios";

export default class AwsService {
    static invokeApig = async ({
                                   path,
                                   method = "GET",
                                   headers = {},
                                   queryParams = {},
                                   body
                               }) => {
        return new Promise(async (resolve, reject) => {
            const userToken = Utils.getFromLocal("currentUser")?.token || null;

            try {
                await AwsService.getAwsCredentials(userToken);
            } catch (e) {
                reject(e);
                return Promise.reject(e);

            }

            const signedRequest = sigV4Client
                .newClient({
                    accessKey: AWS.config.credentials.accessKeyId,
                    secretKey: AWS.config.credentials.secretAccessKey,
                    sessionToken: AWS.config.credentials.sessionToken,
                    region: process.env.REACT_APP_REGION,
                    endpoint: process.env.REACT_APP_API_URL
                })
                .signRequest({
                    method,
                    path,
                    headers,
                    queryParams,
                    body
                });

            body = body ? JSON.stringify(body) : body;
            headers = signedRequest.headers;

            axios.request({
                url: signedRequest.url,
                method,
                headers,
                body
            }).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        });


    }

    static getAwsCredentials = async (userToken) => {
        const authenticator = `cognito-idp.${process.env.REACT_APP_REGION}.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`;

        AWS.config.update({region: process.env.REACT_APP_REGION});

        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
            Logins: {
                [authenticator]: userToken
            }
        });
        return AWS.config.credentials.getPromise();
    }
}
