import produce from 'immer';
import {notification} from "antd";
import {GET_BOARD_FAILED, GET_BOARD_START, GET_BOARD_SUCCESS} from "../types";
import {createSlice} from "@reduxjs/toolkit";
import AwsService from "../../network/awsService";

const initialState = {
    boardId: null,
    detail : null,
    loading: false
}
const boardReducer = createSlice({
    name: "boardReducer",
    initialState,
    reducers: {
        getBoardStart: (state, action) => {
            state.detail = null;
            state.loading = true;
            state.boardId = action?.payload?.boardId
        },
        getBoardSuccess: (state, action) => {
            state.loading = false;
            state.detail = action?.payload?.data;
        },
        getBoardFailed: (state, action) => {
            state.loading = false;
            notification.error({
                message: "Error",
                description: "An error occured"
            });
        }
    }
})
export default boardReducer.reducer;
export const {
    getBoardStart,
    getBoardSuccess,
    getBoardFailed
} = boardReducer.actions;


export const loadBoardDetail = (boardId, dates) => dispatch =>  {
    dispatch(getBoardStart({boardId}));
    return AwsService.invokeApig({
        path:'board',
        queryParams: {
            id: boardId,
            ...dates
        }
    }).then(res => {
        dispatch(getBoardSuccess({data: res.data}));
    }).catch(err => {
        dispatch(getBoardFailed(err));
    })
}
