import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {Table} from "antd";
import dayjs from "dayjs";

class Forms extends Component {
    columns = [
        {
            title: 'Form Name',
            dataIndex: 'name',
            key:1,
            render: (text, user) => {
                return <a href={user.url}>{text}</a>
            }
        },
        {
            title: 'Submissions',
            dataIndex: 'submissionCount',
            key:2,
            sorter: (a, b) => a.submissionCount - b.submissionCount,
        },
        {
            title: 'State',
            dataIndex: 'publishState',
            key:3,
        },
        {
            title: 'Template',
            dataIndex: 'templateName',
            key:4,
        },
        {
            title: 'Creation Date',
            dataIndex: 'creationDate',
            key: 5,
            sorter: (a, b) => dayjs(a.creationDate).unix() - dayjs(b.creationDate).unix(),
            render: (text) => dayjs(text)?.format("DD/MM/YYYY - HH:mm")
        },
        {
            title: 'Update Date',
            dataIndex: 'updateDate',
            key: 5,
            sorter: (a, b) => dayjs(a.updateDate).unix() - dayjs(b.updateDate).unix(),
            render: (text) => dayjs(text)?.format("DD/MM/YYYY - HH:mm")
        },
        {
            title: 'Last Published Date',
            dataIndex: 'lastPublishedDate',
            key: 5,
            sorter: (a, b) => dayjs(a.lastPublishedDate).unix() - dayjs(b.lastPublishedDate).unix(),
            render: (text) => dayjs(text)?.format("DD/MM/YYYY - HH:mm")
        },

    ]
    render() {
        return (
            <Table columns={this.columns}
                   dataSource={this.props.board?.detail?.forms || []}
                   pagination={{
                       defaultPageSize:5
                   }}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        board: state.boardReducer
    };
}

export default connect(
    mapStateToProps,
)(Forms);
