export default class Utils {
    static ucfirst = (string) => {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1)
    }

    static saveLocal = (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    }
    static getFromLocal = (key) => {
        let item = localStorage.getItem(key);
        if (!item) {
            return null;
        }
        return JSON.parse(item);
    }
    static removeFromLocal = (key) => {
        localStorage.removeItem(key);
    }
}

