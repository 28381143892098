import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, Button, Checkbox, Input, Spin} from 'antd'
import "./auth.sass"
import {
    login,
    resetNewPassword,
    sendMFA,
    sendPasswordResetCode,
    verifyForgotPassword
} from "../../services/redux/reducers/authReducer";
import LoginForm from "./LoginForm";
import logo from "../../images/logo.svg";
import MFAForm from "./MFAForm";
import NewPasswordForm from "./NewPasswordForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import ForgotPasswordVerifyForm from "./ForgotPasswordVerifyForm";

class ForgotPassword extends Component {

    sendResetCode = (formData) => {
        this.props.sendPasswordResetCode(formData.username);
    }

    verifyReset = (formData) => {
        this.props.verifyForgotPassword(this.props.auth?.cognitoUser, formData?.code, formData?.newPassword).then(res => {
            this.props.history.push('/auth')
        }).catch(err => {

        })
    }


    renderForm = () => {
        if (this.props.auth?.forgotPasswordCodeSent) {
            return <ForgotPasswordVerifyForm onSubmit={this.verifyReset}/>
        }
        return <ForgotPasswordForm onSubmit={this.sendResetCode} />;
    }

    render() {
        return (
            <div className="container">
                <div className="logo-container">
                    <img src={logo} alt=""/>
                </div>
                <Spin spinning={this.props.auth?.loading}>
                    {
                        this.renderForm()
                    }
                </Spin>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

const mapActionToProps = {
    sendPasswordResetCode: sendPasswordResetCode,
    verifyForgotPassword: verifyForgotPassword
}

export default connect(
    mapStateToProps, mapActionToProps,
)(ForgotPassword);
