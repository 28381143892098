import {combineReducers} from "redux";
import searchReducer from "./searchReducer";
import portalReducer from "./portalReducer";
import boardReducer from "./boardReducer";
import userReducer from "./userReducer";
import authReducer from "./authReducer";


export default combineReducers({
    searchReducer,
    portalReducer,
    boardReducer,
    userReducer,
    auth: authReducer
});
