import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {Table} from "antd";
import dayjs from "dayjs";

class EmailToBoardSubmissions extends Component {
    columns = [
        {
            title: 'Email Address',
            dataIndex: 'email',
            key:1,
            render: (text, user) => {
                return <a href={"mailto:"+text}>{text}</a>
            }
        },
        {
            title: 'Submissions',
            dataIndex: 'submissionCount',
            key:4,
            sorter: (a, b) => a.submissionCount - b.submissionCount,
        },
        {
            title: 'Creation Date',
            dataIndex: 'creationDate',
            key: 5,
            sorter: (a, b) => dayjs(a.creationDate).unix() - dayjs(b.creationDate).unix(),
            render: (text) => dayjs(text)?.format("ddd, DD/MM/YYYY - hh:mm A")
        },

    ]
    render() {
        return (
            <Table columns={this.columns} dataSource={this.props.board?.detail?.emailToBoardSubmissions || []}
                   pagination={{
                       defaultPageSize:5
                   }}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        board: state.boardReducer
    };
}

export default connect(
    mapStateToProps,
)(EmailToBoardSubmissions);
