import axios from "axios";
import Utils from "../Utils";


let axiosInstance = axios.create({
    withCredentials: false,
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000
});
axiosInstance.interceptors.request.use(function (config) {
    const token = Utils.getFromLocal("currentUser")?.token || null;
    config.headers.Authorization = token;
    return config;
});

const AwsService = {
};
AwsService.getAwsCredentials = (userToken) => {

}

export default axiosInstance;
