import React, {Component} from 'react';
import {Button, Form, Input, Spin} from "antd";

class MFAForm extends Component {
    render() {
        return (
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={this.props.onSubmit}
            >
                <Form.Item
                    name="code"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your code!!',
                        },
                    ]}
                >
                    <Input
                        type="text"
                        placeholder="Verification code"
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Send
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default MFAForm;
