import React, {Component} from 'react';
import "./home.sass"
class Home extends Component {
    render() {
        return (
            <div>
                Home Page
            </div>
        );
    }
}

export default Home;
