import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Line} from "@ant-design/charts"
class SubmissionsChart extends Component {
    render() {
        const data = this.props.board?.detail?.submissions || [] ;
        const config ={
            data,
            height: 400,
            xField: 'date',
            yField: 'submissionCount',
            seriesField: 'type',
            point: {
                size : 5 ,
                shape: 'diamond',
            } ,
        } ;
        return <Line{...config}/>;
    }
}

function mapStateToProps(state) {
    return {
        board: state.boardReducer
    };
}

export default connect(
    mapStateToProps,
)(SubmissionsChart);
