import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {CheckCircleOutlined, InfoCircleOutlined, LeftOutlined} from '@ant-design/icons';
import "./portal.sass"
import {loadPortalDetail} from "../../services/redux/reducers/portalReducer"
import {List, Card, Col, Row, Spin, Tag} from "antd";
import dayjs from "dayjs";
import MiniStatCard from "./MiniStatCard";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import CustomChart from "../../components/CustomChart";

class Portal extends Component {

    state = {
        startDate: dayjs().subtract(1, "months"),
        endDate: dayjs()
    }
    componentDidMount() {
        this.props.loadPortalDetail(this.props.match?.params?.id, {
            startDate: this.state.startDate?.format("YYYY-MM-DD"),
            endDate: this.state.endDate?.format("YYYY-MM-DD"),
        });
    }

    onDateChange = ({startDate, endDate}) => {
        this.setState({
            startDate,
            endDate
        }, () => {
            this.props.loadPortalDetail(this.props.match?.params?.id, {
                startDate: startDate?.format("YYYY-MM-DD"),
                endDate: endDate?.format("YYYY-MM-DD"),
            });
        });
    }

    prepareChartData = (data) => {
        let newData = [];
        data.forEach(item => {
           newData.push({
               count: item.pageviewCount || 0,
               date: item.date,
               type: "pageView"
           });
            newData.push({
                count: item.submissionCount || 0,
                date: item.date,
                type: "submission"
            })
        });
        newData.sort((a, b) => {
            return dayjs(a.date) - dayjs(b.date);
        })
        return newData;
    }

    render() {
        let {portal} = this.props;
        return (
            <div className="container">
                <Spin spinning={this.props.portal?.loading}>
                    <Link to={"/search"}><LeftOutlined/> Back to results</Link>
                    <h3>Portal Details</h3>
                    <div className="stats-container">
                        <div className="card-container">
                            <Row>
                                <Col span={8}><img className={"portal-logo"}
                                                   src={this.props.portal?.detail?.logo}/></Col>
                                <Col
                                    className={"portal-url-column"}
                                    span={8} offset={8}>
                                    <a href={portal?.detail?.url} target="_blank">{portal?.detail?.url}</a>
                                </Col>
                            </Row>
                            <Row gutter={20}
                                 wrap
                                 justify={"center"}
                                 className={"sub-information"}
                            >
                                <Col className={"portal-name"} flex={1}>{portal?.detail?.name}</Col>
                                <Col flex={1}>{portal?.detail?.numberOfBoards} boards connected</Col>
                                <Col flex={1}>Status {portal?.detail?.status == "active" ?
                                    <CheckCircleOutlined twoToneColor="#45A736"/> :
                                    <InfoCircleOutlined twoToneColor="#FF0000"/>}</Col>
                                <Col flex={1}><Tag color={"#000"}>{portal?.detail?.isPro ? "Pro" : "Free"}</Tag></Col>
                                <Col flex={1}>{(portal?.detail?.locales || []).join(", ")}</Col>
                                <Col
                                    flex={1}>{dayjs(portal?.detail?.creationDate)?.format("ddd, DD/MM/YYYY - hh:mm A")}</Col>
                            </Row>
                        </div>
                        <div className="mini-stats-container">
                            <Row gutter={24}
                                 wrap
                                 justify={"space-between"}
                            >
                                <Col flex={1}>
                                    <MiniStatCard
                                        title={"Total Pageview"}
                                        subtitle={"Portal total view count"}
                                        count={portal?.detail?.stats?.totalPageview || 0}/>
                                </Col>
                                <Col flex={1}>
                                    <MiniStatCard
                                        title={"Total Submissions"}
                                        subtitle={"Form & email submissions"}
                                        count={portal?.detail?.stats?.totalSubmissions || 0}/>
                                </Col>
                                <Col flex={1}>
                                    <MiniStatCard
                                        title={"Registered Users"}
                                        subtitle={"Registered user on portal"}
                                        count={portal?.detail?.stats?.totalUsers || 0}/>
                                </Col>
                            </Row>
                        </div>
                        <div className="mini-stats-container">
                            <Row
                                gutter={24}
                                wrap
                                justify={"space-between"}>
                                <Col flex={1}>
                                    <div className="stats-card">
                                    <List
                                        size={"large"}
                                        dataSource={portal?.detail?.boards || []}
                                        renderItem={(item) => {
                                            return <div className={"connected-board-item"}>
                                                <Link to={"/board/"+item.id}>{item.name}</Link>
                                                <span>{item.memberCount} members</span>
                                            </div>
                                        }}
                                        header={<h4>Connected Boards</h4>} />
                                    </div>
                                </Col>
                                {/*<Col flex={1}>
                                    <div className="stats-card">
                                        <List
                                            size={"large"}
                                            dataSource={portal?.detail?.mostViewedPages || []}
                                            divider
                                            renderItem={(item) => {
                                                return <div className={"connected-board-item"}>
                                                    <a href={item.url}>{item.name}</a>
                                                </div>
                                            }}
                                            header={<h4>Most Viewed Pages - Top 10</h4>} />
                                    </div>
                                </Col>*/}
                                <Col flex={1}>
                                    <div className="stats-card">
                                        <List
                                            size={"large"}
                                            dataSource={portal?.detail?.socials || []}
                                            divider
                                            renderItem={(item) => {
                                                return <div className={"connected-board-item"}>
                                                    <a href={item.url}>{item.url}</a>
                                                </div>
                                            }}
                                            header={<h4>Website & Social Links</h4>} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="calendar-holder">
                            <CustomDateRangePicker
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onChange={this.onDateChange}
                            />
                        </div>
                        <div className="mini-stats-container">
                            <Row gutter={24}
                                 wrap
                                 justify={"space-between"}
                            >
                                <Col flex={1}>
                                    <MiniStatCard
                                        title={"Total Pageview"}
                                        subtitle={"Portal total view count"}
                                        count={portal?.detail?.filteredStats?.totalPageview || 0}/>
                                </Col>
                                <Col flex={1}>
                                    <MiniStatCard
                                        title={"Total Submissions"}
                                        subtitle={"Form & email submissions"}
                                        count={portal?.detail?.filteredStats?.totalSubmissions || 0}/>
                                </Col>
                                <Col flex={1}>
                                    <MiniStatCard
                                        title={"Registered Users"}
                                        subtitle={"Registered user on portal"}
                                        count={portal?.detail?.filteredStats?.totalUsers || 0}/>
                                </Col>
                            </Row>
                        </div>
                        <div className="stats-chart-container">
                            <div className="header">
                                <h4>Pageview - Submission</h4>

                            </div>
                        <CustomChart
                            data={this.prepareChartData(portal?.detail?.pageViewSubmission || [])}
                            seriesField={"type"}
                            xField={"date"}
                            yField={"count"}
                        />
                        </div>
                    </div>
                </Spin>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        portal: state.portalReducer
    };
}

const mapActionsToProps = {
    loadPortalDetail: loadPortalDetail
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(Portal);
