import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {CheckCircleOutlined, InfoCircleOutlined, LeftOutlined} from '@ant-design/icons';
import "./board.sass"
import {loadPortalDetail} from "../../services/redux/actions/portalActions";
import {Select, List, Card, Col, Row, Spin, Tag, Table} from "antd";
import dayjs from "dayjs";
import MiniStatCard from "./MiniStatCard";
import {loadBoardDetail} from "../../services/redux/reducers/boardReducer"
import EmailToBoardSubmissions from "./EmailToBoardSubmissions";
import SubmissionsChart from "./SubmissionsChart";
import Forms from "./Forms";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";

class Board extends Component {

    state = {
        dates: dayjs().subtract(1, "months").format("DD.MM.YYYY") + " - " + dayjs().format("DD.MM.YYYY"),
        startDate: dayjs().subtract("1", "months"),
        endDate: dayjs()
    }

    componentDidMount() {
        this.props.loadBoardDetail(this.props.match?.params?.id, {
            startDate: this.state.startDate.format("YYYY-MM-DD"),
            endDate: this.state.endDate.format("YYYY-MM-DD")
        });
    }


    onDateChange = ({startDate, endDate}) => {
        this.setState({
            startDate,
            endDate
        }, () => {
            this.props.loadBoardDetail(this.props.match?.params?.id, {
                startDate: startDate.format("YYYY-MM-DD"),
                endDate: endDate.format("YYYY-MM-DD"),
            });
        });

    }

    render() {
        let {board} = this.props;
        return (
            <div className="container">
                <Spin spinning={this.props.board?.loading}>
                    <Link to={"/search"}><LeftOutlined/> Back to results</Link>
                    <h3>Board Details</h3>
                    <div className="stats-container">
                        <div className="card-container">
                            <Row>
                                <Col span={8}>
                                    <h4>{board?.detail?.name}</h4>
                                </Col>
                                <Col
                                    className={"portal-url-column"}
                                    span={8} offset={8}>
                                    <a href={board?.detail?.url} target="_blank">{board?.detail?.url}</a>
                                </Col>
                            </Row>
                            <Row gutter={20}
                                 wrap
                                 justify={"center"}
                                 className={"sub-information"}
                            >
                                <Col flex={1}>{board?.detail?.memberCount || 0} Members</Col>
                                <Col flex={1}>{board?.detail?.activeMemberCount || 0} Active Members</Col>
                                <Col flex={1}>
                                    {board?.detail?.powerUpsCount || 0} Power-Ups Installed
                                </Col>
                                <Col
                                    flex={1}>Created {dayjs(board?.detail?.creationDate)?.format("DD-MM-YYYY")}</Col>
                                <Col flex={1}>Last
                                    Updated {dayjs(board?.detail?.lastUpdateDate)?.format("DD-MM-YYYY")}</Col>
                            </Row>
                        </div>
                        <div className="mini-stats-container">
                            <Row gutter={24}
                                 wrap
                                 justify={"space-between"}
                            >
                                <Col flex={1}>
                                    <MiniStatCard
                                        title={"Form Count"}
                                        subtitle={"Embedded or portal forms"}
                                        count={board?.detail?.stats?.formsCount || 0}/>
                                </Col>
                                <Col flex={1}>
                                    <MiniStatCard
                                        title={"Form Submissions"}
                                        subtitle={"Total form requests"}
                                        count={board?.detail?.stats?.formSubmissions || 0}/>
                                </Col>
                                <Col flex={1}>
                                    <MiniStatCard
                                        title={"Form Impressions"}
                                        subtitle={"Total form view"}
                                        count={board?.detail?.stats?.formImpressions || 0}/>
                                </Col>
                            </Row>
                        </div>
                        {/*<div className="mini-stats-container">
                            <Row
                                gutter={24}
                                wrap
                                justify={"space-between"}>
                                <Col flex={1}>
                                    <div className="stats-card">
                                        <List
                                            size={"large"}
                                            dataSource={board?.detail?.formSubmissions}
                                            renderItem={(item) => {
                                                return <div className={"connected-board-item"}>
                                                    <a target="_blank" rel="noreferrer" href={item.url}>{item.name}</a>
                                                    <span>{item.submissionCount}</span>
                                                </div>
                                            }}
                                            header={<h4>All Form Submissions</h4>}/>
                                    </div>
                                </Col>
                                <Col flex={1}>
                                    <div className="stats-card">
                                        <List
                                            size={"large"}
                                            dataSource={board?.detail?.createdFromScratch}
                                            renderItem={(item) => {
                                                return <div className={"connected-board-item"}>
                                                    <a href={item.url} target="_blank" rel="noreferrer">{item.name}</a>
                                                </div>
                                            }}
                                            header={<h4>Created From Scratch</h4>}/>
                                    </div>
                                </Col>
                                <Col flex={1}>
                                    <div className="stats-card">
                                        <List
                                            size={"large"}
                                            dataSource={board?.detail?.usedTemplateForm}
                                            renderItem={(item) => {
                                                return <div className={"connected-board-item"}>
                                                    <a href={item.url} target="_blank" rel="noreferrer">{item.name}</a>
                                                </div>
                                            }}
                                            header={<h4>Used Templates Form</h4>}/>
                                    </div>
                                </Col>
                            </Row>
                        </div>*/}

                        <div className="calendar-holder">
                            <CustomDateRangePicker
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onChange={this.onDateChange}
                            />
                        </div>
                        <div className={"mini-stats-container"}>
                        <Row>
                            <Col flex={1}>
                                <MiniStatCard
                                    title={"Form Submissions"}
                                    subtitle={"Between Dates"}
                                    count={board?.detail?.filteredStats?.formSubmissions}/>
                            </Col>
                            <Col flex={1}>
                                <MiniStatCard
                                    title={"Form Impressions"}
                                    subtitle={"Between Dates"}
                                    count={board?.detail?.filteredStats?.formImpressions}/>
                            </Col>
                        </Row>
                        </div>
                        <div className="stats-table-container">
                            <h4>Forms</h4>
                            <Forms/>
                        </div>
                        {/*<div className="stats-table-container">
                            <h4>Email to Board Submissions</h4>
                            <EmailToBoardSubmissions/>
                        </div>*/}
                        <div className="stats-chart-container">
                            <div className="header">
                                <h4>Number of Submissions</h4>

                            </div>
                            <SubmissionsChart/>
                        </div>
                    </div>
                </Spin>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        board: state.boardReducer
    };
}

const mapActionsToProps = {
    loadBoardDetail: loadBoardDetail
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(Board);
