import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {CheckCircleOutlined, InfoCircleOutlined, LeftOutlined} from '@ant-design/icons';
import "./user.sass"
import {loadUserDetail} from '../../services/redux/reducers/userReducer';
import {List, Card, Col, Row, Spin, Tag} from "antd";
import dayjs from "dayjs";
import MiniStatCard from "./MiniStatCard";

class User extends Component {

    componentDidMount() {
        this.props.loadUserDetail(this.props.match?.params?.id);
    }

    render() {
        let {user} = this.props;
        return (
            <div className="container">
                <Spin spinning={this.props.user?.loading}>
                    <Link to={"/search"}><LeftOutlined/> Back to results</Link>
                    <h3>User Details</h3>
                    <div className="stats-container">
                        <div className="card-container">
                            <Row justify={"space-between"}>
                                <Col flex={1}>
                                    <div className={"name-container"}>
                                        <span className={"name"}>{user?.detail?.name}</span>
                                        <Tag
                                            color={"#000"}>{user?.detail?.isPro ? "Trello Pro Account" : "Normal Account"}</Tag>
                                    </div>
                                </Col>
                                <Col
                                    flex={1}
                                    className={"user-url-column"}
                                >
                                    <div style={{flex: 1, display: "flex", justifyContent: "flex-end"}}>
                                        <a
                                            style={{marginRight:10}}
                                            href={"mailto://" + user?.detail?.emails?.[0] || "#"}
                                           target="_blank">{user?.detail?.emails?.[0] || ""}</a>
                                        <span>Registered {dayjs(user?.detail?.creationDate)?.format("MMM, DD YYYY")}</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={20}
                                 wrap
                                 justify={"center"}
                                 className={"sub-information"}
                            >
                                <Col flex={1}>
                                    Purpose Of Use: {user?.detail?.purpose}
                                </Col>
                            </Row>
                        </div>

                        <div className="mini-stats-container">
                            <Row
                                gutter={24}
                                wrap
                                justify={"space-between"}>
                                <Col flex={1} span={8}>
                                    <div className="stats-card">
                                        <List
                                            size={"large"}
                                            dataSource={user?.detail?.boards}
                                            renderItem={(item) => {
                                                return <div className={"connected-board-item"}>
                                                    <Link to={"/board/"+item.id}>{item.name}</Link>
                                                    <span>{item.memberCount} Members</span>
                                                </div>
                                            }}
                                            header={<h4>Boards</h4>}/>
                                    </div>
                                </Col>
                                <Col flex={1}>
                                    <div className="stats-card">
                                        <List
                                            size={"large"}
                                            dataSource={user?.detail?.portals}
                                            renderItem={(item) => {
                                                return <div className={"connected-board-item"}>
                                                    <Link to={"/portal/"+item.id}>{item.name}</Link>
                                                    <Tag color={"#000"}>{item?.isPro ? "Pro" : "Free"}</Tag>
                                                </div>
                                            }}
                                            header={<h4>Portals</h4>}/>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Spin>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.userReducer
    };
}

const mapActionsToProps = {
    loadUserDetail: loadUserDetail
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(User);
