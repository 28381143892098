import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import CustomRoute from "./CustomRoute";
import {useSelector} from "react-redux";
const PrivateRoute = (props) => {
    const auth = useSelector(state => state.auth)
    return auth?.currentUser ? <CustomRoute {...props} /> : <Redirect to={"/auth"} />
};

export default PrivateRoute;
