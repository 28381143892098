import React, {Component, Fragment} from 'react';
import {Link, Redirect, Route, Switch} from "react-router-dom";
import logo from "../images/logo-gray.svg";
import {Layout, Menu} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../services/redux/reducers/authReducer";


const CustomRoute = ({component: Component, ...rest}) => {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const menuItems = [
        {
            key: 'home',
            text: 'Home',
            path: '/'
        },
        {
            key: 'search',
            text: 'Search',
            path: '/search'
        },
        {
            key: 'reports',
            text: 'Reports',
            path: '/reports'
        },

    ]
    const renderWithLayout = (props, headless = false) => {
        const {Content, Header, Footer} = Layout;
        return (
            <Fragment>
                {!headless ?
                <Header className={"main-header"}>
                    <div className="logo">
                        <img src={logo} alt=""/>
                    </div>
                    <Menu theme={"dark"} mode="horizontal">
                        {
                            menuItems.map(item => {
                                return <Menu.Item key={item.key}><Link to={item.path}>{item.text}</Link></Menu.Item>
                            })
                        }
                        <Menu.Item
                            style={{float: "right"}}
                            onClick={() => {
                            dispatch(logout())
                            props.history.push('/auth')
                        }}>Logout</Menu.Item>
                    </Menu>

                </Header> : "" }
                <Content className={"main-content"}>
                    <div className="main-container">
                        <Component {...props} />
                    </div>
                </Content>
                <Footer style={{textAlign: 'center', marginTop: 24}}>® 2020 Hipporello</Footer>
            </Fragment>
        )
    }
    const renderComponent = (props, headless = false) => {
        return renderWithLayout(props, headless)
    }

    return (
        rest.shouldUnauthorized && auth?.currentUser != null ? <Redirect to={"/"} /> :
        <Route {...rest} render={props => (
            renderComponent(props, rest.headless)
        )}/>
    )

}

export default CustomRoute;
