import React, {Component} from 'react';
import {Button, Form, Input, Spin} from "antd";

class ForgotPasswordForm extends Component {
    render() {
        return (
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={this.props.onSubmit}
            >
                Please enter username to send reset verification code
                <Form.Item
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!!',
                        },
                    ]}
                >
                    <Input
                        type="text"
                        placeholder="Username"
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Send Verification Code
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default ForgotPasswordForm;
