import React, {Component, lazy, Suspense} from "react"
import {Layout, Menu} from 'antd';
import {withRouter, Switch, Route} from 'react-router-dom';
import logo from "../images/logo-gray.svg";
import "./App.sass"
import {Link} from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import Home from "../pages/home";
import Portal from "../pages/portal";
import Board from "../pages/board";
import User from "../pages/user";
import CustomRoute from "../components/CustomRoute";
import ForgotPassword from "../pages/auth/ForgotPassword";

const Auth = React.lazy(() => import('../pages/auth'))
const Search = React.lazy(() => import('../pages/search'))

class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            current: null
        }

    }

    componentDidMount() {

    }


    render() {
        const {Header, Footer, Content} = Layout;
        return (
            <Layout className="layout">
                <Suspense fallback={<div>Yükleniyor...</div>}>
                    <Switch>
                        <PrivateRoute path={"/"} exact component={Home}/>
                        <CustomRoute shouldUnauthorized={true} headless={true} path={"/auth"} exact component={Auth}/>
                        <CustomRoute shouldUnauthorized={true} headless={true} path={"/auth/forgot-password"} component={ForgotPassword}/>
                        <PrivateRoute path={"/search"} component={Search}/>
                        <PrivateRoute path={"/portal/:id"} component={Portal}/>
                        <PrivateRoute path={"/board/:id"} component={Board}/>
                        <PrivateRoute path={"/user/:id"} component={User}/>
                        <PrivateRoute path={"/reports"}>Reports</PrivateRoute>
                    </Switch>
                </Suspense>
            </Layout>
        );
    }
}

export default withRouter(App);
