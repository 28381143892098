import React, {Component} from 'react';
import {DateRangePicker} from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import {Button, Modal} from "antd";
import * as dayjs from "dayjs";
class CustomDateRangePicker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: this.props.startDate?.toDate(),
            endDate: this.props.endDate?.toDate(),
            key: 'selection',
            isOpen: false
        };
    }

    handleSelect = (ranges) => {
        this.setState({
            startDate: ranges.selection.startDate,
            endDate: ranges.selection.endDate
        })
    }
    handleClose = (resetDate = true) => {
        this.setState({
            isOpen: false,
            startDate: resetDate ? this.props.startDate?.toDate() : this.state.startDate,
            endDate: resetDate ? this.props.endDate?.toDate() : this.state.endDate,
        })
    }
    handleOpen = () => {
        this.setState({isOpen: true})
    }

    onConfirm = () => {
        this.props.onChange({
            startDate: dayjs(this.state.startDate),
            endDate: dayjs(this.state.endDate)
        });
        this.handleClose(false);
    }

    renderDatePickerModal = () => {
        const selectionRange = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            key: 'selection',
        }
        return <div className={"dateRangePickerContainer"}>
            <DateRangePicker
                ranges={[selectionRange]}
                months={2}
                rangeColors={["#001529"]}
                direction="horizontal"
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                onChange={this.handleSelect}
            />
            <div className={"buttons"}>
                <Button className={"primary-color"} onClick={this.onConfirm}>Confirm</Button>
                <Button className={"primary-link dib"} onClick={this.handleClose}>Cancel</Button>
            </div>
        </div>
    }

    renderTrigger = () => {
        let startDate = null;
        let endDate = null;
        if (this.state.startDate && this.state.endDate) {
            startDate = dayjs(this.props.startDate).format("DD.MM.YYYY");
            endDate = dayjs(this.props.endDate).format("DD.MM.YYYY");
        }
        return <Button ghost type="primary" color={"#001529"} loading={this.props.loading} className={"calendar-button"} onClick={this.handleOpen}>
            {
                startDate && endDate ? startDate + " - " + endDate : "Select Date"
            }
        </Button>
    }

    render() {

        return (
            <div>
                {this.renderTrigger()}
                <Modal
                    width={1000}
                    title="Select Date"
                    visible={this.state.isOpen}
                    onCancel={this.handleClose}
                    footer={null}
                >
                    {this.renderDatePickerModal()}
                </Modal>
                {/*<Popup
                trigger={this.renderTrigger()}
                content={this.renderDatePickerModal()}
                on='click'
                open={this.state.isOpen}
                onClose={this.handleClose}
                onOpen={this.handleOpen}
                position='top center'
            />
            */}
            </div>

        )
    }
}
export default CustomDateRangePicker;
