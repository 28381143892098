import React, {Component} from 'react';
import {Button, Form, Input, Spin} from "antd";
import {connect} from "react-redux";

class ForgotPasswordForm extends Component {
    render() {
        return (
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={this.props.onSubmit}
            >
                Your code has sent to {this.props.auth?.codeDeliveryDetails?.AttributeName}: {this.props.auth?.codeDeliveryDetails?.Destination}
                <Form.Item
                    name="code"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your verification code',
                        },
                    ]}
                >
                    <Input
                        type="text"
                        placeholder="Verification Code"
                    />
                </Form.Item>
                <Form.Item
                    name="newPassword"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your new password',
                        },
                    ]}
                >
                    <Input
                        type="password"
                        placeholder="New Password"
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Send
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}
export default connect(mapStateToProps)(ForgotPasswordForm);
