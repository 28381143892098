import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Line} from "@ant-design/charts"
class CustomChart extends Component {
    render() {
        const data = this.props.data || [] ;
        const config ={
            data,
            height: 400,
            xField: this.props.xField,
            yField: this.props.yField,
            seriesField: this.props.seriesField,
            point: {
                size : 5 ,
                shape: 'diamond',
            } ,
        } ;
        return <Line{...config}/>;
    }
}


export default connect(
    null,
)(CustomChart);
