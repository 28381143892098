import {createSlice} from "@reduxjs/toolkit";
import {AuthenticationDetails, CognitoUser, CognitoUserPool} from "amazon-cognito-identity-js";
import Utils from "../../Utils";
import { browserHistory } from 'react-router'

import {notification} from "antd";
import {da} from "react-date-range/dist/locale";

const authReducer = createSlice({
    name: "authReducer",
    initialState: {
        currentUser: Utils.getFromLocal("currentUser"),
        mfaRequired: false,
        loading: false,
        error: null,
        passwordResetRequired: false,
        cognitoUser: null,
        userAttributes: null,
        requiredParams: null,
        forgotPasswordCodeSent: false,
        codeDeliveryDetails: null,
    },
    reducers: {
        authStart: (state, action) => {
            state.loading = true;
            state.currentUser = null;
            state.mfaRequired = false;
            state.cognitoUser = action?.payload?.cognitoUser;
        },
        authSuccess: (state, action) => {
            state.loading = false;
            state.mfaRequired = action.payload?.mfaRequired || false;
            state.currentUser = action?.payload?.userData;
            state.cognitoUser = action?.payload?.cognitoUser;
            console.log(action?.payload?.userData);
            Utils.saveLocal("currentUser", action?.payload?.userData);
            state.passwordResetRequired = false;
        },
        authFailed: (state, action) => {
            state.loading = false;
            state.currentUser = null;
            state.mfaRequired = false;
            Utils.removeFromLocal("currentUser");
        },
        logout: (state, action) => {
            state.loading = false;
            state.currentUser = null
            state.mfaRequired = false;
            let currentUser = Utils.getFromLocal("currentUser")?.username;
            Utils.removeFromLocal("currentUser");
            state.cognitoUser?.signOut();
            state.cognitoUser = null;
            let cognitoUser = new CognitoUser(getUserPoolData(currentUser));
            cognitoUser?.signOut();

        },
        mfaRequired: (state, action) => {
            state.loading = false;
            state.mfaRequired = true;
        },
        mfaStart: (state, action) => {
            state.loading = true;
        },
        mfaFailed: (state, action) => {
            state.loading = false;
        },
        passwordResetRequired: (state, action) => {
            state.mfaRequired = false;
            state.passwordResetRequired = true;
            state.userAttributes =  action?.payload?.userAttributes
            state.requiredParams =  action?.payload?.requiredParams
            state.loading = false;
        },
        forgotPasswordStart : (state, action) => {
            state.loading = true;
        },
        forgotPasswordCodeSent: (state, action) => {
            state.forgotPasswordCodeSent = true
            state.cognitoUser = action.payload?.cognitoUser;
            state.loading = false;
            state.codeDeliveryDetails = action.payload?.codeDeliveryDetails
            console.log("Payload", action.payload);
        },
        forgotPasswordCodeSendFailed: (state, action) => {
            state.loading = false;
        },
        forgotPasswordVerifyStart: (state, action) => {
            state.loading = true;
        },
        forgotPasswordVerifyFailed: (state, action) => {
            state.loading = false;
        },
        forgotPasswordVerifySuccess: (state, action) => {
            state.loading = false;
            state.codeDeliveryDetails = null;
            state.cognitoUser = null;
            state.forgotPasswordCodeSent= false;
        },

    }
});
export default authReducer.reducer;
export const {
    authStart,
    authSuccess,
    authFailed,
    logout,
    mfaRequired,
    mfaStart,
    mfaFailed,
    passwordResetRequired,
    forgotPasswordStart,
    forgotPasswordCodeSendFailed,
    forgotPasswordCodeSent,
    forgotPasswordVerifyStart,
    forgotPasswordVerifySuccess,
    forgotPasswordVerifyFailed
} = authReducer.actions;

export const login = (username, password) => dispatch => {

    return new Promise((resolve, reject) => {
        let authenticationData = {
            Username: username,
            Password: password,
        };
        let authenticationDetails = new AuthenticationDetails(authenticationData);
        let userData = getUserPoolData(username)
        let cognitoUser = new CognitoUser(userData);
        dispatch(authStart({cognitoUser}));
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: result => {
                let userData = loginSuccessData(result);
                dispatch(authSuccess({
                    userData,
                    cognitoUser: cognitoUser
                }));
                resolve({
                    userData: userData,
                    showMfa: false
                });
            }, onFailure: err => {
                notification.error({
                    message: "Error",
                    description: err.message
                });
                dispatch(authFailed({
                    error: err
                }));
                if (err?.code == "PasswordResetRequiredException") {
                    window.location.href= "/auth/forgot-password";
                }
                reject(err);
            },
            mfaRequired: challengeName => {
                dispatch(mfaRequired({cognitoUser}))
            },
            newPasswordRequired : (userAttributes, requiredAttributes) => {
                dispatch(passwordResetRequired({userAttributes, requiredAttributes}));
            }
        });
    });
}

export const resetNewPassword = (cognitoUser, requiredParams, newPassword) => dispatch => {
    cognitoUser?.completeNewPasswordChallenge( newPassword, requiredParams, {
        onSuccess: result => {
            let userData = loginSuccessData(result);
            dispatch(authSuccess({userData}))
        },
        onFailure : err =>  {
            dispatch(authFailed({err}))
            notification.error({
                message: "Error",
                description: err.message
            });
        },
        mfaRequired: challengeName =>  {
            dispatch(mfaRequired());
        }
    })
}

export const sendPasswordResetCode = (username) => dispatch => {
    let userData = getUserPoolData(username)
    let cognitoUser = new CognitoUser(userData);
    dispatch(forgotPasswordStart({
        cognitoUser
    }));
    cognitoUser?.forgotPassword({
        onSuccess: (data) => {
            console.log(data);
            dispatch(
                forgotPasswordCodeSent({
                    cognitoUser,
                    codeDeliveryDetails: data?.CodeDeliveryDetails
                })
            )
        },
        onFailure: (err) => {
            notification.error({
                message: "Error",
                description: err.message
            });
            dispatch(forgotPasswordCodeSendFailed({err}))
        }
    })
}
export const sendMFA = (cognitoUser, code) => dispatch => {
    dispatch(mfaStart());
    return new Promise((resolve, reject) => {
        cognitoUser.sendMFACode(code, {
            onSuccess: (result) => {
                let userData = loginSuccessData(result);
                dispatch(authSuccess({
                    userData
                }));
                resolve({
                    userData: userData,
                    showMfa: false
                });
            },
            onFailure: err => {
                notification.error({
                    message: "Error",
                    description: err.message
                });
                dispatch(mfaFailed())
            },
        });
    })
}

const loginSuccessData = (result) => {
    console.log(result);
    return {
        token: result.getIdToken().getJwtToken(),
        name: result?.getIdToken().payload?.name,
        email: result?.getIdToken().payload?.email,
        username: result?.getIdToken()?.payload?.["cognito:username"]
    }
}

export const verifyForgotPassword = (cognitoUser, code, newPassword)=> dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(forgotPasswordVerifyStart({}));
        cognitoUser.confirmPassword(code, newPassword, {
            onSuccess: result => {
                resolve(result);
                dispatch(forgotPasswordVerifySuccess({
                }))
                notification.success({
                    message: "Success",
                    description: "Your password successfully changed."
                });

            }, onFailure: err => {
                notification.error({
                    message: "Error",
                    description: err.message
                });
                dispatch(forgotPasswordVerifyFailed({
                    error: err
                }));
                reject(err);
            },
            mfaRequired: challengeName => {
                dispatch(mfaRequired({cognitoUser}))
            },
            newPasswordRequired : (userAttributes, requiredAttributes) => {
                dispatch(passwordResetRequired({userAttributes, requiredAttributes}));
            }
        });
    });
}

const getUserPoolData = (username) => {
    let poolData = {
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        ClientId: process.env.REACT_APP_APP_CLIENT_ID
    };
    let userPool = new CognitoUserPool(poolData);
    return {
        Username: username,
        Pool: userPool
    };
}
