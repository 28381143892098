import {notification} from "antd";
import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../network/axiosInstance";
import AwsService from "../../network/awsService";

const initialState = {
    type: "user",
    results: [],
    triggered: false,
    loading: false,
    term: ""
}
const searchReducer = createSlice({
    name: "searchReducer",
    initialState,
    reducers: {
        changeSearchType: (state, action) => {
            state.type = action?.payload?.type;
            state.results = [];
            state.triggered = false;
        },
        searchStart: (state, action) => {
            state.term = action?.payload?.term
            state.loading = true;
            state.results = []
        },
        searchSuccess: (state, action) => {
            state.loading = false;
            state.triggered = true
            state.results = action?.payload?.data;
        },
        searchFailed: (state, action) => {
            state.triggered = true
            state.loading = false;
            notification.error({
                message: "Error",
                description: action.payload?.data?.message || "An error occured"
            });
        }
    }
})
export default searchReducer.reducer;
export const {
    changeSearchType,
    searchFailed,
    searchStart,
    searchSuccess,
} = searchReducer.actions;

export const triggerSearch = (type, term, page = 1) => (dispatch) => {
    dispatch(searchStart({term}));
    let params = {
        name: term,
        pageSize:20,
        page
    };
    let path = "";
    switch (type) {
        case "user":
            path = "search/user";
            break;
        case "board":
            path = "search/board";
            break;
        case "portal":
            path = "search/portal";
            break;
    }
    AwsService.invokeApig({
        path,
        method: "GET",
        queryParams: params,
    }).then(res => {
        dispatch(searchSuccess({
            data: res.data
        }));
        return Promise.resolve(res.data);
    }).catch(err => {
        dispatch(searchFailed({
            data: err
        }))
        return Promise.reject(err);
    })
    /*return axiosInstance.get(path, {
        params
    }).then(res => {
        dispatch(searchSuccess({
            data: res.data
        }));
        return Promise.resolve(res.data);
    }).catch(err => {
        dispatch(searchFailed({
            data: err
        }))
        return Promise.reject(err);
    })*/
}
