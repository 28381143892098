import produce from 'immer';
import {notification} from "antd";
import {GET_USER_FAILED, GET_USER_START, GET_USER_SUCCESS} from "../types";
import axios from "axios";
import faker from "faker";
import {createSlice} from "@reduxjs/toolkit";
import axiosInstance from "../../network/axiosInstance";
import AwsService from "../../network/awsService";


const initialState = {
    userId: null,
    detail : null,
    loading: false
}

const userReducer = createSlice({
    name: "userReducer",
    initialState,
    reducers: {
        getUserStart: (state, action) => {
            state.detail = null;
            state.loading = true;
            state.userId = action?.payload?.userId
        },
        getUserSuccess: (state, action) => {
            state.loading = false;
            state.detail = action?.payload?.data;
        },
        getUserFailed: (state, action) => {
            state.loading = false;
            notification.error({
                message: "Error",
                description: "An error occured"
            });
        }
    }
});
export default userReducer.reducer;
export const {
    getUserFailed,
    getUserSuccess,
    getUserStart
} = userReducer.actions;


export const loadUserDetail = (userId) => dispatch =>  {
    dispatch(getUserStart({userId}));
    return AwsService.invokeApig({
        path: 'user',
        queryParams: {
            id: userId
        }
    }).then(res => {
        dispatch(getUserSuccess({data: res.data}));
    }).catch(err => {
        console.log(err);
        console.log("error");
    })
}

const getMockUserData = () => ({
    name: faker.name.findName(),
    isPro: faker.helpers.randomize([true,false]),
    email: faker.internet.email(),
    purpose: "HR Management",
    boards: Array(10).fill(null).map(e =>
        e = {
            id: faker.random.uuid(),
            name: faker.commerce.productName(),
            memberCount: faker.random.number(50),
        }),
    portals: Array(3).fill(null).map(e =>
        e = {
            id: faker.random.uuid(),
            name: faker.commerce.productName(),
            isPro: faker.helpers.randomize([true, false]),
        }),



})

