import {notification} from "antd";
import {createSlice} from "@reduxjs/toolkit";
import axiosInstance from "../../network/axiosInstance";
import AwsService from "../../network/awsService";
const initialState = {
    portalId: null,
    detail : null,
    loading: false
}
const portalReducer = createSlice({
    name: "portalReducer",
    initialState,
    reducers: {
        getPortalStart: (state, action) => {
            state.detail = null;
            state.loading = true;
            state.portalId = action.payload?.id;
        },
        getPortalSuccess : (state, action) => {
            state.loading = false;
            state.detail = action?.payload?.data
        },
        getPortalFailed : (state, action) => {
            state.loading = false;
            notification.error({
                message: "Error",
                description: "An error occured"
            });
        }
    }
})

export default portalReducer.reducer;

export const {
    getPortalFailed,
    getPortalStart,
    getPortalSuccess
} = portalReducer.actions;

export const loadPortalDetail = (portalId, dates = null) => dispatch => {
    dispatch(getPortalStart({portalId}))
    return AwsService.invokeApig({
        path: "portal",
        queryParams: {
            id: portalId,
            ...dates
        }
    }).then(res => {
        dispatch(getPortalSuccess({data: res.data}))
        return Promise.resolve(res.data);
    }).catch(err => {
        dispatch(getPortalFailed({error:err}))
        return Promise.reject(err);
    });
}
