import React, {Component} from 'react';
import {Col} from "antd";

class MiniStatCard extends Component {
    render() {
        return (
            <div className="stats-card">
                <span className={"number"}>{this.props.count}</span>
                <div className={"info"}>
                    <span className="title">
                        {this.props.title}
                    </span>
                    <span className="subtitle">
                        {this.props.subtitle}
                    </span>
                </div>
            </div>
        );
    }
}

export default MiniStatCard;
